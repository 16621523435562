.iconDetailItem {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 100%;
  padding: 32px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to right, #fff, #fff), linear-gradient(90deg, rgba(87, 100, 241, 1), rgba(200, 87, 241, 1));
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    border: 2px solid transparent;
    border-radius: 16px;
    opacity: 0;
    transition: ease-in-out 0.4s;
    content: '';
  }

  // &:hover {
  //   &::after {
  //     opacity: 1;
  //     transition: ease-in-out 0.24s;
  //   }

  //   :global {
  //     .item-text {
  //       &.is-title {
  //         color: #5764f1;
  //       }
  //     }
  //   }
  // }

  // &:not(:hover) {
  //   :global {
  //     .icon-space {
  //       filter: grayscale(100%);
  //       transition: ease-in-out 0.16s;
  //     }
  //   }
  // }

  :global {
    .icon-space {
      position: relative;
      z-index: 33;
      display: inline-flex;
      transition: ease-in-out 0.16s;

      svg {
        height: 43px;
      }
    }

    .item-text {
      position: relative;
      z-index: 33;
      color: #86909c;
      font-weight: 400;
      font-size: 14px;
      font-family: var(--font-family-Regular);
      line-height: 1.6;

      &.is-title {
        color: rgba(29, 33, 41, 1);
        font-weight: 600;
        font-size: 24px;
        font-family: var(--font-family-SemiBold);
        transition: ease-in-out 0.24s;
      }
    }
  }
}
