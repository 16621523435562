.creativityBox {
  padding: 120px 0 80px;
}

.mainImg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 720px;
  margin-bottom: 16px;
  overflow: hidden;

  :global {
    .video-box {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &.is-loop {
        opacity: 0;
      }

      .video-inner {
        position: relative;

        // 遮掩视频文件自带的黑色线
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 2px;
          background-color: #fff;
          content: '';
        }
      }

      video {
        height: 1496px;
        margin-top: 168px;

        @media screen and (max-width: 1280px) {
          width: 120%;
          height: unset;
          margin-top: 0;
          margin-left: -10%;
        }
      }
    }
  }

  @media screen and (max-width: 1280px) {
    height: 480px;
  }

  @media screen and (max-width: 960px) {
    height: 320px;
  }
}

.creativityFramInnerBox {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 1334px) {
    gap: 24px;
  }
}

.suppoutIconList {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-items: center;
}

.iconSupport {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 106px;
  height: 106px;
  margin-left: -24px;
  border: 4px solid transparent;
  border-radius: 16px;

  svg {
    path {
      fill: #4e5969;
    }

    @media screen and (max-width: 1334px) {
      width: 40px;
    }
  }

  @media screen and (max-width: 1334px) {
    width: unset;
    height: unset;
    margin-left: 0;
  }

  // &:hover {
  //   background-image: linear-gradient(to right, #fff, #fff), linear-gradient(90deg, rgba(87, 100, 241, 1), rgba(200, 87, 241, 1));
  //   background-clip: padding-box, border-box;
  //   background-origin: padding-box, border-box;

  //   // 装饰点 START
  //   &::after,
  //   &::before {
  //     position: absolute;
  //     display: flex;
  //     background-color: #b453f0;
  //     border-radius: 99px;
  //     content: '';
  //   }

  //   &::before {
  //     top: -18px;
  //     right: -14px;
  //     width: 12px;
  //     height: 3px;
  //     transform: rotate(-74deg);
  //   }

  //   &::after {
  //     top: -9px;
  //     right: -31px;
  //     width: 26px;
  //     height: 3px;
  //     transform: rotate(-30deg);
  //   }

  //   // 装饰点 END

  //   svg {
  //     path {
  //       fill: rgba(87, 100, 241, 1);
  //     }
  //   }
  // }
}

.supperIconBox {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (max-width: 1024px) {
    gap: 16px;
  }
}

.supportWrapper {
  display: flex;
  gap: 48px;
  justify-content: space-between;

  @media screen and (max-width: 1334px) {
    gap: 32px;
    padding: 0 24px;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.supportTitle {
  padding-top: 56px;
}
