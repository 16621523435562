.boxFrame {
  display: flex;
  justify-content: center;

  :global {
    .frame-content {
      width: 100%;
      max-width: 1334px;
    }
  }
}
