*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
/* Remove the margin in Firefox and Safari */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}
a,
dd,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
dl {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
body {
  margin: 0;
  padding: 0;
  background-color: var(--color-bg-canvas);
  color: var(--color-text-primary);
  font-family: 'MonaSans-Regular', 'Microsoft YaHei', '微软雅黑', 'Helvetica',
    'Arial', sans-serif;
}
a {
  color: var(--color-text-link);
  text-decoration: none;
}

.mdi-set {
  line-height: 1;
}

table td {
  overflow-wrap: break-word;
  word-break: break-all;
}
/* 动画类名 */
.g-animation-progress-bar {
  animation-name: slidein;
  animation-duration: 4.8s;
}
.g-animation-rocket-hide {
  animation-name: toHide;
  animation-duration: 0.8s;
  animation-delay: 2.4s;
  animation-fill-mode: forwards;
}
.g-animation-rocket-show {
  animation-name: toShow;
  animation-duration: 0.8s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}
@keyframes toProgress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes slidein {
  from {
    right: 100%;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}
@keyframes toHide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes toShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
