.leadStudios {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1334px;

  @media screen and (max-width:1024px) {
    gap: 24px !important;
  }

  :global {
    .lead-studios-inner {
      width: 100%;
      max-width: 1200px;
    }

    .lead-studios-text {
      font-size: 18px;
      margin-top: 64px;
      color: #4e5969;

      @media screen and (max-width:1024px) {
        font-size: 14px;
      }
    }
  }
}

.leadStudiosLogo {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width:1024px) {
    flex-wrap: wrap;
    gap: 32px;
  }

  @media screen and (max-width:640px) {
    justify-content: flex-start;
  }

  :global {
    .logo-item {

      &:nth-child(1) {
        img {
          @media screen and (max-width:1024px) {
            width: 96px;
          }

          @media screen and (max-width:960px) {
            width: 80px;
          }
        }
      }

      &:nth-child(2) {
        img {
          @media screen and (max-width:1024px) {
            width: 160px;
          }

          @media screen and (max-width:960px) {
            width: 120px;
          }
        }
      }

      &:nth-child(3) {
        img {
          @media screen and (max-width:1024px) {
            width: 48px;
          }

          @media screen and (max-width:960px) {
            width: 32px;
          }
        }
      }

      &:nth-child(4) {
        img {
          @media screen and (max-width:1024px) {
            width: 120px;
          }

          @media screen and (max-width:960px) {
            width: 88px;
          }
        }
      }

      &:nth-child(5) {
        img {
          @media screen and (max-width:1024px) {
            width: 96px;
          }

          @media screen and (max-width:960px) {
            width: 64px;
          }
        }
      }
    }
  }
}