@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  to {
    background-position: 0 50%;
  }
}

.u-skeleton .skeleton-row {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.u-skeleton .skeleton-row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding: 10px;
}

.u-skeleton .skeleton-row > .col {
  flex: 1 0 0%;
}

.u-skeleton .skeleton-row > .col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.u-skeleton .skeleton-row > .col-4 {
  flex: 0 0 auto;
  width: 33.33%;
}

.u-skeleton .skeleton-row > .col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.u-skeleton .skeleton-row > .col-8 {
  flex: 0 0 auto;
  width: 66.66%;
}

.u-skeleton .skeleton-row > .col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.u-skeleton .skeleton-row > .col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.u-skeleton .skeleton-image {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-border-secondary);
}

.u-skeleton .skeleton-image svg {
  width: auto;
  height: 22%;
  fill: var(--color-border-tertiary);
}

.u-skeleton .skeleton-h {
  height: 22px;
  border-radius: 4px;
  background-color: var(--color-border-secondary);
  margin-bottom: 15px;
}

.u-skeleton .skeleton-p {
  height: 16px;
  border-radius: 4px;
  background-color: var(--color-border-secondary);
  margin-bottom: 10px;
}

.u-skeleton .skeleton-btn {
  height: 30px;
  border-radius: 4px;
  background-color: var(--color-border-secondary);
}

.u-skeleton .skeleton-circle,
.u-skeleton .skeleton-square {
  width: 40px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}

.u-skeleton .skeleton-circle::before,
.u-skeleton .skeleton-square::before {
  content: '';
  display: block;
  padding-top: 100%;
  background-color: var(--color-border-secondary);
}

.u-skeleton .skeleton-square {
  border-radius: 4px;
}

.u-skeleton.is-animated .skeleton-image,
.u-skeleton.is-animated .skeleton-h,
.u-skeleton.is-animated .skeleton-p,
.u-skeleton.is-animated .skeleton-btn,
.u-skeleton.is-animated .skeleton-circle::before,
.u-skeleton.is-animated .skeleton-square::before {
  background: linear-gradient(90deg, var(--color-border-secondary) 25%, var(--color-border-primary) 37%, var(--color-border-secondary) 63%);
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
}
