body {
  .ant-message .anticon {
    position: relative;
    top: -2px !important;
  }

  #app,
  .ingame-wrapper {
    height: 100vh;
  }
}
