.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 600px;
  margin-top: -60px;
  padding-top: 168px;
  padding-left: 40px;
  padding-right: 40px;

  @media screen and (max-width:768px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  // background: url(../../../assets/images/banner-bg.png) no-repeat center top;
  // background-size: 125% auto;
  // scroll-snap-align: start;
  // width: 100%;
  // overflow: hidden;
  &.isCenter {
    .bannerInfoBox {
      align-items: center;

      :global {
        h2 {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .extra-text {
          text-align: center;
        }
      }
    }
  }

  :global {
    .banner-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }

    .banner-inner {
      display: inline-flex;
      flex-direction: column;
      margin: 0px auto;
    }

    .active {
      border-radius: 4px;
    }
  }
}

:global {
  .ant-carousel {
    display: flex;
    flex-direction: column;

    .slick-slider {
      display: flex;
      flex-direction: column;

    }

    .slick-dots-parent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: -38px;
      padding: 0 24px;

      @media screen and (max-width:768px) {
        padding-top: 16px;
      }


      .slick-dots {
        // margin-left: auto;
        display: flex;
        flex-direction: row;
        justify-content: left;
        width: 100%;
        max-width: 1334px;
        position: static !important;
        margin: 0px auto;
        height: 10px;

        li {
          // 切换条
          width: 10px;

          button {
            &:hover &:focus {
              opacity: 0.75;
            }
          }

          &:first-child {
            margin-left: 0px;
          }
        }

        li.slick-active {
          // 当前切换条
          width: 48px !important;
          opacity: 1;
        }
      }

      .slick-dots-bottom {
        li {
          button {
            position: relative;
            display: block;
            width: 100%;
            height: 10px;
            padding: 0;
            color: transparent;
            font-size: 0;
            background: #5764f1;
            border: 0;
            border-radius: 1px;
            outline: none;
            cursor: pointer;
            transition: all 0.5s;
          }
        }
      }
    }

  }

  .lead-container {
    width: 100%;
    padding: 0 24px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.boxFrame {
  display: flex;
  justify-content: center;

  :global {
    .frame-content {
      width: 100%;
      max-width: 1334px;
    }
  }
}

.bannerInfoBox {
  z-index: 99;
  display: inline-flex;
  flex-direction: column;

  :global {
    h2 {
      display: flex;
      flex-direction: column;


      .banner-text {
        display: flex;
        color: #1d2129;
        font-weight: 700;
        font-size: 80px;
        height:88px;
        font-family: var(--font-family-Bold);
        line-height: 88px;

        @media screen and (max-width:1180px) {
          font-size: 64px;
          line-height: 1.2;
        }

        @media screen and (max-width:960px) {
          font-size: 56px;
          height: 56px;
          line-height: 1.1;
        }

        @media screen and (max-width:640px) {
          font-size: 48px;
          height: 48px;
          line-height: 1;
        }

        &+.banner-text {
          margin-top: -16px;
        }

        &.is-small {
          font-size: 60px;
          margin-top: 8px !important;
          line-height: 68px;
          height: auto !important;;
          @media screen and (max-width:1180px) {
            font-size: 56px;
            line-height: 1.2;
          }
  
          @media screen and (max-width:960px) {
            font-size: 50px;
            line-height: 1.1;
          }
  
          @media screen and (max-width:640px) {
            font-size: 42px;
            line-height: 1;
          }
        }

        &.is-color-primary {
          color: transparent;
          // background-image: linear-gradient(to right, #f53f3f 22%, #f77234, #5764f1 79%);
          background-image: linear-gradient(to right, #f53f3f 12%, #f77234, #5764f1 47%);
          -webkit-background-clip: text;
          position: relative;
          margin-top: 16px;

          @media screen and (max-width:1180px) {
            margin-top: 0;
          }

          .star-icon {
            top: 4px;
            left: 597px;
            position: absolute;
          }

          .line-icon {
            left: 495px;
            top: -135px;
            position: absolute;
          }
        }
      }
    }

    .extra-text {
      font-size: 16px;
      color: #4E5969;
      line-height: 24px;
      margin-top: 16px;
      margin-bottom: 56px;
      font-family: var(--font-family-Medium);

      @media screen and (max-width:1180px) {
        font-size: 16px;
      }

      @media screen and (max-width:640px) {
        margin-bottom: 24px;
      }
    }

    .text-content-cn {
      .star-icon {
        left: 570px !important;
        top: -3px !important;
        height: 34px;
        width: 34px;
      }

      .line-icon {
        left: 470px !important;
        top: -115px !important;
      }

      .extra-text {
        font-size: 22px;
        color: #1D2129;
        line-height: 32px;
        margin-top: 16px;
        margin-bottom: 56px;
      }

      .blue-text {
        color: #5764f1;
      }
    }
    .black-text{
      font-size: 22px !important;
      color: #1D2129 !important;
      line-height: 32px !important;
      display: inline-block;
      margin-top: 10px;
      @media screen and (max-width: 1180px) {
        font-size: 16px !important;
        line-height: 24px !important;
      }
    }
    .banner-bg1{
      z-index: -1;
      position: absolute;
      left: 48.5%;
      top: 110px;

      @media screen and (max-width: 1600px) {
        left: 40% !important;
      }
    }

    .banner-bg2 {
      z-index: -1;
      position: absolute;
      left: 38.5%;
      top: 170px;

      @media screen and (max-width: 1600px) {
        left: 33% !important;
      }
    }
    .banner-bg3{
      z-index: -1;
      position:absolute;
      left: 43%;
      top: 90px;
      @media screen and (max-width: 1600px) {
        left: 40% !important;
      }
    }
    // 中文
    .bg-content-cn {
      .banner-bg1 {
        z-index: -1;
        position: absolute;
        left: 45.5%;
        top: 115px;

        @media screen and (max-width: 1600px) {
          left: 42% !important;
        }
      }

      .banner-bg2 {
        z-index: -1;
        position: absolute;
        left: 37%;
        top: 170px;

        @media screen and (max-width: 1600px) {
          left: 31% !important;
        }
      }
      .banner-bg3{
        z-index: -1;
        position:absolute;
        left: 41.5%;
        top: 90px;
        @media screen and (max-width: 1600px) {
          left: 39% !important;
        }
      }
    }
    .is-btn-primary {
      padding: 16px 50px !important;
      height: 56px;
    }
  }
}