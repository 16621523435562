.itemTitle {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;

  &.isAlignStart {
    display: inline-flex;
    width: unset;
  }
}

.itemTitleText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-weight: 700;
  font-size: 56px;
  font-family: var(--font-family-Bold);
  line-height: 72px;
  letter-spacing: -0.8px;

  @media screen and (max-width:1334px) {
    font-size: 48px;
    line-height: 1.4;
  }

  @media screen and (max-width:960px) {
    font-size: 40px;
    line-height: 1.2;
  }

  @media screen and (max-width:768px) {
    font-size: 32px;
  }

  @media screen and (max-width:480px) {
    font-size: 24px;
  }

  &:not(.isInline) {
    flex-direction: column;
  }

  &.isInline {
    gap: 12px;
  }

  &.isSmall {
    font-size: 40px;
    font-family: var(--font-family-SemiBold);

    @media screen and (max-width:1334px) {
      font-size: 32px;
    }

    @media screen and (max-width:768px) {
      font-size: 24px;
    }
  }

  &.isMini {
    font-size: 32px;
    font-family: var(--font-family-SemiBold);
    line-height: 28px;
    letter-spacing: -0.8px;

    @media screen and (max-width:1334px) {
      font-size: 24px;
    }

    @media screen and (max-width:960px) {
      font-size: 20px;
    }

    @media screen and (max-width:768px) {
      font-size: 18px;
    }
  }

  &.isXMini {
    font-size: 24px;

    @media screen and (max-width:1334px) {
      font-size: 20px;
    }

    @media screen and (max-width:768px) {
      font-size: 16px;
    }
  }

  :global {
    .text-item {
      &.color-primary {
        margin-right: 0;
        color: #5764f1;
      }

      &.color-blue {
        color: rgba(22, 93, 255, 1);
      }

      &.color-orange {
        color: rgba(247, 114, 52, 1);
      }

      &.color-green {
        color: rgba(64, 212, 207, 1);
      }
      &.color-black{
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}

.titleExtraList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;

  &.isLarge {
    :global {
      .list-item {
        color: #86909c;
        font-size: 20px;
        line-height: 32px;

        @media screen and (max-width:1024px) {
          font-size: 16px;
          line-height: 1.6;
        }
      }
    }
  }

  :global {
    .list-item {
      display: flex;
      color: #4e5969;
      font-weight: 400;
      font-size: 16px;
      font-family: var(--font-family-Regular);
      line-height: 24px;
      text-align: center;
    }
  }
}

.itemTitleWrapper {
  padding-top: 0;

  &.isAlignStart {
    display: inline-flex;
    justify-content: flex-start;
  }
}