.getStartedTryFreeBox {
  padding: 0 24px 214px 24px;

  @media screen and (max-width:1334px) {
    padding-bottom: 120px;
  }
}

.introduceItem1 {

  :global {
    .frame-content {
      position: relative;

      @media screen and (max-width:1334px) {
        padding-top: 0;
      }

      &::after {
        position: absolute;
        top: 436px;
        left: 230px;
        width: 474.23px;
        height: 269.21px;
        background: url(../../assets/images/p-get-started-img-line-01.png) no-repeat;
        background-size: contain;
        content: '';

        @media screen and (max-width:1334px) {
          display: none;
        }
      }
    }

    .item-right {
      margin-left: -200px;

      @media screen and (max-width:1334px) {
        margin: 0;
      }
    }
  }
}

.introduceItem2 {
  :global {
    .frame-content {
      @media screen and (max-width:1334px) {
        padding-top: 0;
      }
    }

    .item-right {
      margin-right: -16px;

      @media screen and (max-width:1334px) {
        margin: 0;
      }
    }
  }
}

.introduceItem3 {
  padding-bottom: 96px;

  @media screen and (max-width:1334px) {
    padding-bottom: 0;
  }

  :global {
    .frame-content {
      position: relative;
      padding-top: 96px;

      @media screen and (max-width:1334px) {
        padding-top: 0;
      }

      &::after {
        position: absolute;
        top: -140px;
        left: 284px;
        width: 474.23px;
        height: 269.21px;
        background: url(../../assets/images/p-get-started-img-line-02.png) no-repeat;
        background-size: contain;
        content: '';

        @media screen and (max-width:1334px) {
          display: none;
        }
      }
    }

    .item-right {
      margin-left: -184px;

      @media screen and (max-width:1334px) {
        margin: 0;
      }
    }
  }
}