.globalNotification {
  position: fixed;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 24px 12px 24px;
  background-image: linear-gradient(90deg, #252688 0%, #2b31b0 56.21%, #4628bd 83.03%, #a64dc6 100%);

  .notificationItem {
    display: inline-flex;
    flex: 1;
    align-items: center;
    color: #acbaf9;
    font-family: var(--font-family-Regular);
    font-style: normal;

    @media screen and (max-width:560px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .title {
    display: inline-flex;
    align-items: center;
    margin-right: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    img {
      margin-right: 8px;
    }
  }

  .content {
    display: inline-flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    p {
      margin-bottom: 0;
    }

    a {
      color: #e8eeff;
    }
  }

  .closeIcon {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}