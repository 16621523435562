.developersBox {
  padding: 240px 0 118px;
  background-color: rgba(247, 248, 250, 1);

  @media screen and (max-width:1134px) {
    padding-top: 200px;
    padding-bottom: 160px;
  }
}

.developerInfoBox {
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 32px;

  :global {
    h3 {
      max-width: 720px;
      color: rgb(29, 33, 41);
      font-weight: 700;
      font-size: 56px;
      font-family: var(--font-family-Bold);
      line-height: 1.2;
      letter-spacing: -0.8px;

      @media screen and (max-width:1134px) {
        font-size: 40px;
      }

      @media screen and (max-width:960px) {
        font-size: 32px;
      }

      .color-import {
        display: flex;
        width: 100%;
        color: rgba(245, 78, 78, 1);
      }
    }

    .developer-info {
      max-width: 640px;
      color: rgba(78, 89, 105, 1);
      font-size: 16px;
      font-family: var(--font-family-Regular);
      line-height: 1.6;
      letter-spacing: -0.2px;

      &.is-color-import {
        margin-top: 32px;
        color: rgba(87, 100, 241, 1);
      }
    }
  }
}

.detailList {
  display: flex;
  gap: 32px;

  @media screen and (max-width:768px) {
    flex-direction: column;
  }

  :global {
    .list-item {
      flex: 1;
      height: 284px;

      @media screen and (max-width:1334px) {
        height: unset;
      }

      @media screen and (max-width:768px) {
        flex: unset;
      }
    }
  }
}

.developerDetailListBox {
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.developerDetailTitle {
  color: #1d2129;
  font-weight: 600;
  font-size: 40px;
  font-family: var(--font-family-SemiBold);
  line-height: 48px;

  @media screen and (max-width:1134px) {
    font-size: 24px;
  }
}

.developersInnerBox {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 112px;
  padding: 0 24px;
}

.sideImgBox {
  position: absolute;
  top: -168px;
  right: -196px;
  z-index: 99;
  width: 784px;
  height: 806px;
  overflow: hidden;

  @media screen and (max-width:1134px) {
    width: 784px;
    height: 806px;
  }

  :global {
    video {
      position: absolute;
      top: -96px;
      right: -210px;
      width: 2108px;

      @media screen and (max-width:1134px) {
        width: 1280px;
        top: -72px;
        right: -80px;
      }
    }
  }
}