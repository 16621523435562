.layoutDefaultBox {
  position: relative;
  min-width: 320px;
  min-height: 100vh;
  background: url(../../../assets/images/banner-bg.png) no-repeat center top;
  background-size: 130% auto;

  // scroll-snap-align: start;
  // width: 100%;
  // overflow: hidden;
  :global {

    .layout-content {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }
}