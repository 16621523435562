.footerComponent {
  padding: 88px 0 120px;
  background-color: #f7f8fa;

  @media screen and (max-width:1180px) {
    padding: 40px 24px 80px;
  }

  :global {
    .frame-content {
      overflow: hidden;
    }
  }
}

.footerListBox {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (max-width:1180px) {
    gap: 8px;
  }


  :global {
    dt {
      color: #1d2129;
      font-weight: 600;
      font-size: 19px;
      font-family: var(--font-family-SemiBold);
      text-transform: uppercase;
    }

    dd {
      &.list-extra-text {
        .list-disable-click {
          color: #86909c;
          font-weight: 400;
          font-size: 14px;
          font-family: var(--font-family-Regular);
          cursor: default;
        }
      }

      a,
      span {
        color: #4e5969;
        font-weight: 400;
        font-size: 18px;
        font-family: var(--font-family-Regular);
        cursor: pointer;


        @media screen and (max-width:1180px) {
          font-size: 16px;
        }

        @media screen and (max-width:768px) {
          font-size: 14px;
        }

        &:hover {
          color: #5764f1;
        }
      }
    }
  }
}

.footerCopyRight {
  color: #1d2129;
  font-weight: 400;
  font-size: 18px;
  font-family: var(--font-family-Regular);

  @media screen and (max-width:1180px) {
    font-size: 14px;
  }

  @media screen and (max-width:768px) {
    font-size: 12px;
  }

  .link {
    margin-left: 3px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

.footerEndLink {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width:1180px) {
    flex-direction: column;
    gap: 8px;
  }

  :global {
    a {
      color: #5764f1;
      font-weight: 400;
      font-size: 18px;
      font-family: var(--font-family-Regular);

      @media screen and (max-width:1180px) {
        font-size: 14px;
      }

    }
  }
}

.footerSpace {
  @media screen and (max-width:1180px) {
    gap: 40px !important;
  }

}