.statementBox {
  padding: 96px 24px;
  background-color: #f7f8fa;
}

.statementInnerBox {
  display: flex;
  gap: 123px;
  justify-content: center;
  padding-top: 56px;

  @media screen and (max-width:768px) {
    gap: unset;
    justify-content: space-around;
  }

  :global {
    .statemen-icon-box {

      img {
        @media screen and (max-width:768px) {
          width: 160px;
          height: unset;
        }
      }
    }
  }
}