*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  /* font */
  --font-family-Bold: MonaSans-Bold;
  --font-family-Medium: MonaSans-Medium;
  --font-family-Regular: MonaSans-Regular;
  --font-family-SemiBold: MonaSans-SemiBold;

  font-family: var(--font-family-Regular);
}

.webkit-box {
  display: flex;
}

.flex1 {
  flex: 1;
}

.flex-start {
  align-items: flex-start;
  justify-content: start;
}

.flex-left {
  align-items: center;
  justify-content: start;
}

.flex-end {
  align-items: flex-end;
  justify-content: end;
}

.flex-space-between {
  align-items: center;
  justify-content: space-between;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.text-left {
  text-align: left;
}

.rel {
  position: relative;
}

.none {
  display: none !important;
}

.hidden {
  visibility: hidden;
}

.m-t {
  margin-top: var(--spacing);
}

.m-b {
  margin-bottom: var(--spacing);
}

.m-l {
  margin-left: var(--spacing);
}

.m-r {
  margin-right: var(--spacing);
}

.p-t {
  padding-top: var(--spacing);
}

.p-b {
  padding-bottom: var(--spacing);
}

.p-l {
  padding-left: var(--spacing);
}

.p-r {
  padding-right: var(--spacing);
}

.p-b-half {
  padding-bottom: var(--spacing-half);
}

.p-r-half {
  padding-right: var(--spacing-half);
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.pointer-all {
  * {
    cursor: pointer;
  }
}

.cursor-default {
  cursor: default;

  * {
    cursor: default;
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown-style-select {
  margin-right: 10px;

  .u-input-inner {
    border: transparent;
  }
}

.w-60 {
  width: 60px !important;
}

.w-120 {
  width: 120px !important;
}

.w-130 {
  width: 130px !important;
}

.w-140 {
  width: 140px !important;
}

.measure-layout-box {
  .measure-layout-content {
    flex: 1;
    padding: var(--spacing-double);

    .u-tabs-content {
      padding: var(--spacing-double) 0 0 0;
    }
  }
}

.maxWidth-100-off {
  max-width: 100%;
}

.word-break {
  word-break: break-word;
}
