@font-face {
  font-family: 'TTTGBMedium';
  src: url(../fonts/TTTGB-Medium.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'MonaSans-Bold';
  src: url(../fonts/MonaSans-Bold.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MonaSans-Medium';
  src: url(../fonts/MonaSans-Medium.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MonaSans-Regular';
  src: url(../fonts/MonaSans-Regular.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MonaSans-SemiBold';
  src: url(../fonts/MonaSans-SemiBold.otf) format('opentype');
  font-weight: 600;
  /* Adjust the font weight as needed */
  font-style: normal;
}

/* gc-page */
.gc-page {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  min-height: 100vh;
  // background-color: #fafbfd;

  .gc-page-headline {
    position: fixed;
    top: 0;
    z-index: 999;
  }

  .gc-page-content {
    flex: 1 1 100%;
    min-width: 1px;
  }

  .p-footer {
    padding: 10px 0px;

    p {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 3px;

      .copyright {
        color: #86909c;

        .link {
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      a {
        color: #5764f1;
        margin: 0 6px;
      }
    }

    .line {
      color: #86909c;
    }
  }
}

/* button */
.gc-btn {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid transparent;
  cursor: pointer;
  background-color: transparent;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 12px;
  border-radius: 2px;
  box-shadow: none;

  .btn-icon {
    .icon-add {
      width: 12px;
      height: 12px;
      vertical-align: inherit;
    }

    .icon-chevron {
      width: 14px;
      height: 14px;
    }
  }

  > span {
    line-height: 1;

    + span {
      margin-left: 4px;
    }
  }

  &.gc-btn-default {
    font-family: var(--font-family-Bold);
    border: 1px solid #c9cdd4;
    color: #1d2129;
    border-radius: 4px;

    &:hover {
      border: 1px solid #363dc8;
      color: #363dc8;
    }
  }

  &.gc-btn-primary {
    background-image: linear-gradient(-89deg, #8f9ff7 0%, #5764f1 100%);
    border-radius: 4px;
    font-family: var(--font-family-Bold);
    font-weight: 500;
    font-size: 14px;
    color: #f6f6f6;
    line-height: 22px;
    height: 32px;
    min-width: 128px;
    text-align: center;
    border: none;

    &:hover {
      background-image: linear-gradient(270deg, #363dc8 0%, #1d1f9f 100%);
    }
  }

  &.gc-btn-primary:disabled,
  &.gc-btn-primary:disabled:hover {
    color: #ffffff;
    background-image: linear-gradient(-89deg, #8f9ff7 0%, #5764f1 100%);
    opacity: 0.5;
  }

  &.gc-btn-primary-outline {
    border-color: #165dff;
    color: #165dff;

    &:hover {
      background-color: #165dff;
      color: #fff;
    }
  }

  &.gc-btn-danger {
    background: #f54e4e;
    color: #fff;

    &:hover {
      background: #a1161f;
    }
  }

  &.gc-btn-white {
    border-color: #fff;
    background-color: transparent;
    color: #fff;
  }

  &.is-large {
    font-family: var(--font-family-Bold);
    font-size: 16px;
    line-height: 24px;
    padding: 13px 22px;
  }
  &.is-small {
    font-family: var(--font-family-Medium);
    font-weight: 500;
    font-size: 12px;
    padding: 3px 15px;
    min-width: 42px;
    height: 24px;
    line-height: 18px;
  }

  &.is-block {
    display: block;
    width: 100%;
  }

  &.disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
}

/* input */
.gc-input {
  position: relative;

  .gc-input-inner {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid #e5e6eb;
    background-color: transparent;
    padding: 5px 12px;
    border-radius: 2px;
    appearance: none;

    &:disabled {
      background-color: #f3f6fb;
      cursor: not-allowed;
    }

    &[readonly] {
      background-color: #f3f6fb;
    }

    &::placeholder {
      color: #bbb;
    }

    &:not(:first-child) {
      padding-left: 32px;
    }

    &:not(:last-child) {
      padding-right: 32px;
    }

    &:not(:disabled):hover {
      border-color: #165dff;
    }

    &:not(:disabled):focus {
      border-color: #165dff;
      outline: none;
    }
  }

  .gc-input-suffix {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: 6px;
    color: #bbb;

    > svg {
      display: block;
      width: 16px;
      height: 16px;
      margin: 2px;
    }
  }

  &.gc-input-plaintext {
    .gc-input-inner[readonly] {
      background-color: transparent;
      border-color: transparent;
      padding-left: 0;
      padding-right: 0;

      &:disabled {
        color: #999;
      }

      &:hover,
      &:focus {
        border-color: transparent;
      }
    }
  }

  &.is-large {
    .gc-input-inner {
      font-size: 16px;
      line-height: 24px;
      padding: 13px 22px;
    }
  }

  &.is-error {
    .gc-input-inner {
      border-color: #ff5e61;
    }
  }
}

/* form */
.gc-form {
  .field-item {
    margin-bottom: 16px;

    > .field-label {
      > label {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        line-height: 20px;
        padding: 6px 0;
      }
    }

    > .field-content {
      .field-error {
        margin-top: 8px;
        display: flex;
        flex-wrap: nowrap;
        color: #ff5e61;

        .error-icon {
          padding: 1px 6px 1px 0;

          svg {
            width: 16px;
            height: 16px;
            display: block;
          }
        }

        .error-text {
          font-size: 12px;
          line-height: 18px;
        }
      }

      .field-help {
        margin-top: 8px;
        font-size: 12px;
        line-height: 18px;
        color: #999;
      }
    }
  }

  &.gc-form-label-left {
    .field-item {
      display: flex;
      flex-wrap: nowrap;

      > .field-label {
        width: 160px;
        min-width: 160px;
        padding-right: 10px;
      }

      > .field-content {
        flex: 1 1 100%;
        min-width: 1px;
      }
    }
  }
}

.ant-popover-arrow-content {
  --antd-arrow-background-color: #27272a;
}

.ant-form-item-label > label {
  font-size: 16px;
  color: #1d2129;
  letter-spacing: 0;
  line-height: 24px;
}

.ant-form-item-control-input {
  min-height: 40px;

  .ant-form-item-control-input-content {
    .ant-input,
    .ant-select-selector {
      min-height: 40px;
      border-radius: 4px;
      display: flex;
      align-items: center;

      &:hover {
        border: 1px solid #5764f1;
      }

      &:focus,
      &:active {
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        border: 1px solid #5764f1;
        box-shadow: 0 0 0 3px rgba(87, 100, 241, 0.1);
      }
    }

    .ant-input::placeholder {
      color: #86909c;
    }

    .c-antd-input-xl-main {
      min-height: 40px;
    }

    .c-antd-select-xl-main {
      .ant-select-selector {
        border-radius: 4px;
        min-height: 40px;

        .ant-select-selection-search-input {
          height: 40px;
        }

        .ant-select-selection-placeholder {
          color: #86909c;
          line-height: 22px;
          display: flex;
          align-items: center;
        }

        .ant-select-selection-item {
          color: #1d2129;
          display: flex;
          align-items: center;
        }
      }
    }

    .ant-input-affix-wrapper {
      height: 40px;
      border-radius: 6px;

      .ant-input {
        min-height: 36px;
        top: -3px;
        border: none !important;
        box-shadow: none !important;

        &:hover {
          border: none;
        }

        &:focus,
        &:active {
          border: none;
          box-shadow: none;
        }
      }

      &:hover {
        border: 1px solid #5764f1;
      }
    }
  }
}

.ant-form-item-has-error .ant-input {
  border: 1px solid #f54e4e;

  &:hover {
    border: 1px solid #f53f3f !important;
  }

  &:focus,
  &:active {
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    border: 1px solid #f53f3f !important;
    box-shadow: 0 0 0 3px rgba(245, 63, 63, 0.1) !important;
  }
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper-status-success.ant-input-affix-wrapper-focused {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border: 1px solid #5764f1 !important;
  box-shadow: 0 0 0 3px rgba(87, 100, 241, 0.1) !important;
}

.ant-input-affix-wrapper-status-error.ant-input-affix-wrapper-focused,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):focus-within .ant-select-selector {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border: 1px solid #f53f3f !important;
  box-shadow: 0 0 0 3px rgba(245, 63, 63, 0.1) !important;
}

/* table */
.gc-table {
  width: 100%;
  margin-bottom: 15px;

  > thead > tr {
    > td,
    > th {
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
      background-color: #fafafd;
      text-align: left;
      vertical-align: bottom;
      border-bottom: 1px solid #eaedf2;
      padding: 8px 14px;
    }
  }

  > tbody > tr {
    > td,
    > th {
      font-weight: normal;
      padding: 10px 14px;
      font-size: 12px;
      line-height: 20px;
      border-top: 1px solid #eaedf2;
      color: #666;
    }
  }
}

/* status */
.gc-status {
  display: inline-flex;
  flex-wrap: nowrap;

  .status-icon {
    color: #a5a5a5;
    padding: 2px 0;

    svg {
      display: block;
      width: 16px;
      height: 16px;
    }

    + .status-text {
      padding-left: 6px;
    }
  }

  .status-text {
    line-height: 20px;
  }

  &.status-success {
    .status-icon {
      color: #5edfd6;
    }
  }
}

/* breadcrumb */
// .gc-breadcrumb {
//   display: flex;
//   flex-wrap: wrap;
//   margin: -4px -12px;
//   padding: 0;
//   list-style: none;

  > li {
    position: relative;
    padding: 4px 12px;

    &:not(:last-child)::before {
      position: absolute;
      right: -9px;
      top: 50%;
      margin-top: -10px;
      color: #a5a5a5;
      width: 18px;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      content: '/';
    }

    > a {
      position: relative;
      display: block;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
      color: #666;

      &:hover {
        color: #2857ef;
      }
    }
  }
// }

/* alert */
.gc-alert {
  display: flex;
  flex-wrap: nowrap;
  padding: 12px 20px;
  background-color: rgba(14, 127, 240, 0.1);
  border-radius: 3px;

  .alert-icon {
    padding-right: 10px;
    color: #0e7ff0;

    svg {
      display: block;
      width: 16px;
      height: 16px;
      margin: 2px 0;
    }
  }

  .alert-content {
    flex: 1;
    font-size: 14px;
    line-height: 20px;
  }

  .alert-action {
    font-size: 0;

    .alert-link {
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      line-height: 20px;
      margin-left: 10px;
      color: #165dff;
      cursor: pointer;
    }

    .alert-btn {
      display: inline-block;
      vertical-align: top;
      border-radius: 2px;
      font-size: 14px;
      line-height: 20px;
      margin-left: 10px;
      margin-top: -3px;
      margin-bottom: -3px;
      padding: 3px 8px;
      background-color: #165dff;
      color: #fff;
      cursor: pointer;
    }
  }

  &.gc-alert-warning {
    background-color: #fff3e0;

    .alert-icon {
      color: #bf360c;
    }

    .alert-content {
      color: #bf360c;
    }

    .alert-action {
      .alert-link {
        color: #bf360c;
      }

      .alert-btn {
        background-color: #bf360c;
      }
    }
  }
}

/* modal */
.gc-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  z-index: 1100;
  display: none;

  .gc-modal-dialog {
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 800;
    padding: 40px;
    min-height: 100%;
    justify-content: center;

    .gc-modal-content {
      pointer-events: auto;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 560px;
      background-color: #fff;
      border-radius: 2px;

      .gc-modal-header {
        display: flex;
        flex-wrap: nowrap;
        padding: 12px 16px;
        border-bottom: 1px solid #eff0f3;

        > .col-title {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
        }

        > .col-action {
          margin-left: auto;
        }

        .gc-modal-close {
          display: block;
          padding: 4px 2px;
          background-color: transparent;
          appearance: none;
          cursor: pointer;
          border: none;
          color: #c9cdd4;

          svg {
            display: inline-block;
            vertical-align: top;
            width: 16px;
            height: 16px;
          }

          &:hover {
            color: #f53f3f;
          }
        }
      }

      .gc-modal-body {
        padding: 40px;

        .gc-form:last-child {
          > .field-item {
            margin-bottom: 20px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .gc-modal-footer {
        display: flex;
        flex-wrap: nowrap;
        border-top: 1px solid #eff0f3;

        > .col-right {
          margin-left: auto;
          font-size: 0;

          .gc-btn {
            margin-left: 8px;
            min-width: 80px;
          }
        }
      }
    }
  }

  .gc-modal-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 760;
  }

  &.is-show {
    display: block;
  }
}

.delete-modal {
  .flex-start,
  .remove-text {
    margin-bottom: 34px;
  }

  .gc-btn-primary {
    background: #f54e4e !important;

    &:hover,
    &:focus {
      background: #a1161f !important;
    }
  }
}

/* message */
.ant-message-notice-content {
  height: 36px !important;
  box-shadow: 0 2px 8px 0 #0000001a;
  border-radius: 6px;
  padding: 6px 16px 8px 12px;

  .ant-message-custom-content {
    font-family: var(--font-family-Regular);
    font-size: 14px;
    color: #1d2129;

    img {
      width: 14px;
      height: 14px;
      margin-right: 8px;
      position: relative;
      top: -1px;
    }

    span {
      line-height: 22px;
    }
  }
}

/* tips */
.ant-popover-placement-topLeft .ant-popover-arrow {
  left: 12px;
}

.ant-popover-inner-content {
  background: #27272a !important;
  border-radius: 4px !important;
  // padding: 6px 8px 7px;
  padding: 8px 18px;
  min-height: 31px;
  font-size: 12px;
  font-family: var(--font-family-Regular);
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 18px;

  .ant-popover-message-title {
    color: #ffffff;
  }
}

.ant-select-dropdown {
  border-radius: 8px;
}
