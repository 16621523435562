.ant-btn {
  display: inline-flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  text-shadow: unset;
  border-radius: 4px;
  box-shadow: unset;

  // 自定义用来被调用的状态样式
  &.primary-type {
    color: #f6f6f6;
    background-image: linear-gradient(-89deg, #8f9ff7 0%, #5764f1 100%);
    border: unset;
  }

  &.ant-btn-primary {
    color: #f6f6f6;
    font-size: 12px;

    &:hover {
      color: #f6f6f6;
      background-image: linear-gradient(270deg, #363dc8, #1d1f9f);
    }

    &:focus {
      color: #f6f6f6;
      background-image: linear-gradient(270deg, #363dc8, #1d1f9f);
    }

    &.ant-btn-disabled {
      opacity: 0.48;
    }

    @extend .primary-type;
  }
}

:not(.ant-input-group-addon) {
  & > .ant-btn {
    &.ant-btn-lg {
      height: 56px;
      padding: 0 16px;
      font-weight: 700;
      font-size: 18px;
      font-family: var(--font-family-Bold);
      letter-spacing: 0.8px;
      border-radius: 8px;
    }
  }
}
