.ant-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin: 0;
  padding: 0 16px;
  font-weight: 600;
  font-size: 20px;
  font-family: var(--font-family-SemiBold);
  border: unset;
  border-radius: 6px;

  &.g-ant-orange {
    color: #f77e45;
    background-color: rgba(247, 126, 69, 0.2);
  }

  &.g-ant-green {
    color: #40d4cf;
    background-color: rgba(64, 212, 207, 0.2);
  }

  &.g-ant-blue {
    color: rgba(22, 93, 255, 1);
    background-color: rgba(22, 93, 255, 0.2);
  }

  &.g-ant-yellow {
    color: rgba(247, 186, 30, 1);
    background-color: rgba(247, 186, 30, 0.2);
  }

  &.g-ant-purple {
    color: rgba(114, 46, 209, 1);
    background-color: rgba(114, 46, 209, 0.2);
  }
}
