.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 600px;
  margin-top: -60px;
  padding: 168px 24px 88px;

  @media screen and (max-width:1180px) {
    padding-top: 120px;
  }

  &.isCenter {
    @media screen and (max-width:1180px) {
      min-height: unset;
    }

    .bannerInfoBox {
      align-items: center;

      :global {
        h2 {
          display: flex;
          flex-direction: column;
          gap: 16px;
          align-items: center;
        }

        .extra-text {
          text-align: center;
        }
      }
    }
  }

  :global {
    .banner-inner {
      display: flex;
      flex-direction: column;
      gap: 96px;
      justify-content: center;
    }
  }
}

.bannerInfoBox {
  display: inline-flex;
  flex-direction: column;

  :global {
    h2 {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .banner-text {
        display: flex;
        color: #1d2129;
        font-weight: 700;
        font-size: 80px;
        font-family: var(--font-family-Bold);
        line-height: 88px;

        @media screen and (max-width:1180px) {
          line-height: 1.6;
        }

        &+.banner-text {
          margin-top: -16px;
        }

        &.is-small {
          font-size: 64px;

          @media screen and (max-width:1180px) {
            font-size: 56px;
          }

          @media screen and (max-width:960px) {
            font-size: 48px;
          }

          @media screen and (max-width:768px) {
            font-size: 32px;
          }
        }

        &.is-color-primary {
          color: transparent;
          background-image: linear-gradient(to right, #f53f3f 24%, #f77234, #5764f1 88%);
          -webkit-background-clip: text;

          @media screen and (max-width:1180px) {
            font-size: 64px;
            line-height: 1.2;
          }

          @media screen and (max-width:960px) {
            font-size: 56px;
          }

          @media screen and (max-width:768px) {
            font-size: 48px;
          }
        }
      }
    }

    .extra-text {
      color: #1d2129;
      font-size: 22px;

      @media screen and (max-width:1180px) {
        font-size: 18px;
      }

      @media screen and (max-width:960px) {
        font-size: 16px;
      }

      @media screen and (max-width:768px) {
        font-size: 14px;
      }
    }
  }
}