.backTopBox {
  position: fixed;
  right: 24px;
  bottom: 48px;
  z-index: 1002;
  display: inline-flex;
  cursor: pointer;
  transition: 1.6s ease-in-out;

  &.isScrolling {
    opacity: 0.64;
    transition: ease-in-out 0.16s;
  }

  &.isStopScrolling {
    opacity: 1;
    transition: ease-in-out 0.16s !important;
  }

  &.isDistanceBotton {
    bottom: 448px;
    transition: ease-in-out 0.8s;
  }

  &.isNotDistanceBotton {
    bottom: 48px;
    transition: bottom ease-in-out 1.28s;
  }

  &.hasBackTop {
    opacity: 0;
    transition: ease-in-out 0.4s;
  }
}
