.icon-arrow {
  margin-left: 4px;

  .mdi-set {
    font-size: 16px;
  }
}

.drop-down-box {
  display: inline-flex;
  align-items: baseline;
  cursor: pointer;
  user-select: none;

  .box-title {
    color: #1d2129;
    line-height: 1;
  }
}
