:root {
  /* Text */
  --color-text-primary: #1d2129;
  --color-text-secondary: #586069;
  --color-text-tertiary: #6a737d;
  --color-text-placeholder: #6a737d;
  --color-text-disabled: #959da5;
  --color-text-inverse: #fff;
  --color-text-link: #0366d6;
  --color-text-danger: #cb2431;
  --color-text-success: #22863a;
  --color-text-warning: #b08800;
  --color-text-purple: #5a32a3;
  --color-text-white: #fff;
  --color-text-white-keep: #fff;
  --color-text-primary-keep: #24292e;
  --color-text-secondary-keep: #959da5;
  --color-text-link-keep: #0366d6;

  /* Backgrounds */
  --color-bg-canvas: #fff;
  --color-bg-canvas-inverse: #24292e;
  --color-bg-canvas-inset: #f6f8fa;
  --color-bg-primary: #fff;
  --color-bg-secondary: #fafbfc;
  --color-bg-tertiary: #f6f8fa;
  --color-bg-overlay: #fff;
  --color-bg-backdrop: rgba(27, 31, 35, 0.5);
  --color-bg-info: #f1f8ff;
  --color-bg-info-inverse: #0366d6;
  --color-bg-danger: #ffeef0;
  --color-bg-danger-inverse: #d73a49;
  --color-bg-success: #dcffe4;
  --color-bg-success-inverse: #28a745;
  --color-bg-warning: #fff5b1;
  --color-bg-warning-inverse: #ffd33d;
  --color-bg-purple: #e6dcfd;
  --color-bg-purple-inverse: #6f42c1;
  --color-bg-canvas-inverse-keep: #24292e;
  --color-bg-canvas-keep: #fff;
  --color-bg-info-inverse-keep: #0366d6;

  /* Borders */
  --color-border-primary: #e1e4e8;
  --color-border-secondary: #ebedef;
  --color-border-tertiary: #d1d5da;
  --color-border-overlay: #e1e4e8;
  --color-border-inverse: #fff;
  --color-border-info: #0366d6;
  --color-border-danger: #d73a49;
  --color-border-success: #28a745;
  --color-border-warning: #f9c513;
  --color-border-purple: #6f42c1;
  --color-border-white-keep: #fff;

  /* shadow */
  --color-shadow-small: 0 1px 0 rgba(27, 31, 35, 0.04);
  --color-shadow-medium: 0 3px 6px rgba(149, 157, 165, 0.15);
  --color-shadow-large: 0 8px 24px rgba(149, 157, 165, 0.2);
  --color-shadow-extra-large: 0 10px 48px rgba(149, 157, 165, 0.3);
  --color-shadow-highlight: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  --color-shadow-inset: inset 0 1px 0 rgba(225, 228, 232, 0.2);

  /* hover */
  --color-hover-secondary: #586069;
  --color-hover-info: #005cc5;
  --color-hover-success: #2c974b;
  --color-hover-danger: #cb2431;
  --color-hover-warning: #f9c513;
}
