.introduceItemWrapper {
  &.hasBg {
    background-color: #f7f8fa;
  }

  &.isEven {
    .introduceItem {
      flex-direction: row-reverse;

      @media screen and (max-width:1334px) {
        flex-direction: column-reverse;
      }
    }
  }
}

.introduceItem {
  display: flex;
  gap: 32px;
  align-items: stretch;
  padding: 108px 0 128px;

  @media screen and (max-width:1334px) {
    flex-direction: column-reverse;
    padding: 88px 0;

  }

  :global {
    .item-left {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;

      &.has-items-list {
        justify-content: unset;
        padding-top: 72px;

        @media screen and (max-width:1334px) {
          padding-top: 0;
        }
      }
    }

    .item-right {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;

      img {
        @media screen and (max-width:1180px) {
          max-width: 80%;
          height: unset;
        }
      }
    }
  }
}

.introduceItemInfoBox {
  @media screen and (max-width:1334px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  :global {
    .item-title {
      padding-top: 24px;
      color: #1d2129;
      font-weight: 600;
      font-size: 40px;
      font-family: var(--font-family-SemiBold);
      line-height: 48px;
      letter-spacing: -0.8px;

      @media screen and (max-width:1334px) {
        font-size: 24px;
        line-height: 1.2;
      }
    }

    .item-extra {
      padding: 24px 0 16px;
      color: #4e5969;
      font-weight: 400;
      font-size: 16px;
      font-family: var(--font-family-Regular);
      line-height: 24px;
      letter-spacing: 0;
    }
  }
}

.introduceItemSelect {
  max-width: 600px;
  margin-top: 48px;
  margin-left: -32px;
  list-style: none;

  @media screen and (max-width:1334px) {
    max-width: unset;
  }

  @media screen and (max-width:768px) {
    margin: 0;
  }
}

.selectItemList {
  position: relative;
  overflow: hidden;

  &.isEven {
    :global {
      .c-radio-input {
        &:checked {
          cursor: unset;

          &+.c-select-item-inner {
            background-color: rgba(229, 230, 235, 0.3);
          }
        }
      }
    }
  }

  &+.selectItemList {
    margin-top: 32px;
  }

  :global {
    .c-radio-input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;

      &:not(:checked) {
        cursor: pointer;

        &+.c-select-item-inner {
          .c-item-detail-info {
            height: 0;
            background-color: rgba(#f8f8fb, 0);
            opacity: 0;
            transition: opacity ease-in-out 0.8s, background-color ease-in-out 0.8s;
          }
        }
      }

      &:checked {
        &+.c-select-item-inner {
          padding: 32px;
          background-color: rgba(#f8f8fb, 1);
          border-radius: 8px;
          transition: background-color ease-in-out 0.16s;

          &+.c-progress-bar {
            opacity: 1;
          }
        }
      }
    }

    .c-progress-bar {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 9999;
      display: inline-flex;
      width: 400px;
      height: 2px;
      background-image: linear-gradient(90deg, #eee0 1%, #5764f1 99%, #fff 100%);
      opacity: 0;
    }

    .c-select-item-inner {
      padding: 0 32px;
    }

    .c-item-title {
      display: flex;
      gap: 8px;
      align-items: center;

      @media screen and (max-width:640px) {
        align-items: flex-start;
      }

      .title-text {
        flex: 1;
        color: #1d2129;
        font-weight: 600;
        font-size: 20px;
        font-family: var(--font-family-SemiBold);
        text-align: left;
      }
    }

    .c-item-detail-info {
      width: 100%;
      height: auto;
      margin-top: 4px;
      overflow: hidden;
      color: #4e5969;
      font-weight: 400;
      font-size: 16px;
      font-family: var(--font-family-Regular);
      line-height: 1.3;
      white-space: normal;
      text-align: left;
      opacity: 1;
      transition: opacity ease-in-out 0.8s;
    }
  }
}