.wordBox {
  padding-top: 148px;
  padding-bottom: 210px;

  @media screen and (max-width: 1180px) {
    padding-top: 88px;
    padding-bottom: 120px;
  }
}

.earthBoxInnerBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1008px;

  :global {
    .earth-icon-box {
      position: relative;
      display: flex;
      width: 1334px;
      height: 1008px;
      padding: 40px 0 48px;
      overflow: hidden;

      &::after {
        position: absolute;
        right: 0;
        bottom: 14px;
        left: 0;
        height: 16px;
        background-color: white;
        content: '';
      }

      video {
        position: absolute;
        top: -24px;
        left: -260px;
        transform: scale(0.86);

        @media screen and (max-width: 1334px) {
          position: unset;
          top: unset;
          left: unset;
          width: 100%;
          transform: unset;
        }
      }

      @media screen and (max-width: 1334px) {
        height: unset;
      }
    }

    .spline-viewer {
      transform: scale(0.7);

      @media screen and (max-width: 1334px) {
        transform: scale(0.6);
      }

      @media screen and (max-width: 960px) {
        transform: scale(0.5);
      }

      @media screen and (max-width: 768px) {
        transform: scale(0.4);
      }

      @media screen and (max-width: 640px) {
        transform: scale(0.24);
      }
    }
  }

  @media screen and (max-width: 1334px) {
    height: 800px;
  }

  @media screen and (max-width: 960px) {
    height: 768px;
  }

  @media screen and (max-width: 768px) {
    height: 480px;
  }

  @media screen and (max-width: 640px) {
    height: 400px;
  }
}

.deploymentText {
  color: #86909c;
  font-size: 16px;
  font-family: var(--font-family-Regular);

  :global {
    .color-import {
      color: #4e5969;
    }
  }

  @media screen and (max-width: 960px) {
    font-size: 14px;
  }
}

.wordDeploymentWrapper {
  display: flex;
  gap: 24px;
  padding: 0 24px;

  :global {
    .word-deployment-main-box {
      flex: 1;
    }

    .word-deployment-main-box,
    .word-deployment-side-box {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  @media screen and (max-width: 1334px) {
    flex-direction: column;
  }
}

.deploymentRegionsName {
  padding-bottom: 56px;
}

.providersName {
  padding: 80px 0 56px;
}

.providersItem {
  position: relative;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  min-height: 88px;
  padding: 8px 32px;
  border: 3px solid transparent;
  border-radius: 12px;
  box-shadow: 0 2px 8px 0 #0000001a;

  // &:hover {
  //   background-image: linear-gradient(to right, #fff, #fff), linear-gradient(90deg, rgba(87, 100, 241, 1), rgba(200, 87, 241, 1));
  //   background-clip: padding-box, border-box;
  //   background-origin: padding-box, border-box;

  //   // 装饰点 START
  //   &::after,
  //   &::before {
  //     position: absolute;
  //     display: flex;
  //     background-color: #b453f0;
  //     border-radius: 99px;
  //     content: '';
  //   }

  //   &::before {
  //     top: -18px;
  //     right: -14px;
  //     width: 12px;
  //     height: 3px;
  //     transform: rotate(-74deg);
  //   }

  //   &::after {
  //     top: -9px;
  //     right: -31px;
  //     width: 26px;
  //     height: 3px;
  //     transform: rotate(-30deg);
  //   }

  //   // 装饰点 END
  // }

  // &:not(:hover) {
  //   :global {
  //     .icon-item {
  //       img {
  //         filter: grayscale(100%);
  //       }
  //     }
  //   }
  // }

  :global {
    .name-item {
      color: #1d2129;
      font-weight: 600;
      font-size: 24px;
      font-family: var(--font-family-SemiBold);
      letter-spacing: -0.8px;

      @media screen and (max-width: 1334px) {
        font-size: 20px;
      }

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
}

.providersWrapper {
  padding: 0 24px;
}

.worldItemTitle {
  :global {
    h2 {
      @media screen and (max-width: 640px) {
        display: flex;
        flex-direction: column;
        gap: 4px !important;
      }
    }
  }
}
