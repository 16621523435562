.homeTryFreeBox {
  padding: 0 24px 156px 24px;

  @media screen and (max-width: 1180px) {
    padding-bottom: 120px;
  }
}

.detaileBoxTitle {
  padding-top: 144px;
}

.introduceItemHome {
  height: 746px;

  :global {
    .has-items-list {
      @media screen and (max-width: 1334px) {
        min-width: unset !important;
        margin: 0 auto;
        padding: 0 24px 24px;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .frame-content {
      padding: 0;
    }
  }

  &.is01 {
    :global {
      .has-items-list {
        min-width: 606px;
      }
    }
  }

  &.is02 {
    // min-height: 100vh;

    :global {
      .has-items-list {
        justify-content: center;
        min-width: 600px;
        padding-top: 0;
      }
    }
  }

  &.is03 {
    // min-height: 100vh;

    :global {
      .has-items-list {
        justify-content: center;
        min-width: 596px;
        padding-top: 0;
      }
    }
  }

  &.is04 {
    // min-height: 100vh;

    :global {
      .has-items-list {
        justify-content: center;
        min-width: 630px;
        padding-top: 0;
      }
    }
  }

  &.is05 {
    // min-height: 100vh;

    :global {
      .has-items-list {
        justify-content: center;
        min-width: 650px;
        padding-top: 0;
      }
    }
  }

  @media screen and (max-width: 1334px) {
    height: unset;
  }
}
