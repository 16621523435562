.modal-wrap {
  .ant-btn {
    svg {
      vertical-align: baseline;
    }
  }

  .ant-modal-content {
    padding: 34px 24px;
    background: #fff;
    border: 1px solid #f2f3f5;
    border-radius: 12px !important;
    box-shadow: 0 2px 8px 0 #0000001a;
  }

  .ant-modal-close {
    top: 24px;
    right: 24px;

    .ant-modal-close-x {
      // width: 48px;
      // height: 48px;
      // color: #c9cdd4;
      // font-size: 14px;
      // line-height: 48px;

      // &:hover {
      //   color: #f53f3f;
      // }
      width: 14px;
      height: 14px;
      line-height: 14px;
      // .ant-modal-close-icon{
      //   background-image: url('../../assets//images/close-modal.png');
      // }
    }
  }

  .ant-modal-header {
    height: 26px !important;
    margin-bottom: 32px;
    padding: 0 !important;
    line-height: 26px;
    background: unset;
    border-bottom: none !important;

    .ant-modal-title,
    .ant-modal-title span {
      color: #1d2129;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
    }
  }

  .ant-modal-body {
    // margin-top: 32px;
    // margin-bottom: 32px;
    padding: 0;

    input,
    textarea {
      border-radius: 6px;
    }
  }

  .ant-modal-footer {
    padding: 0;
    border-top: none !important;
  }

  .gc-modal-footer {
    display: flex;
    flex-wrap: nowrap;

    > .col-right {
      margin-top: 32px;
      margin-left: auto;

      .gc-btn {
        min-width: 80px;
        border: none;
      }

      .gc-btn:not(:first-child) {
        margin-left: 8px;
      }
    }

    .gc-btn-default {
      width: 80px;
      height: 32px;
      color: #1d2129;
      font-weight: 400;
      font-size: 14px;
      font-family: var(--font-family-Medium);
      background: #fff;
      border: 1px solid #c9cdd4 !important;
      border-radius: 4px;

      &:hover {
        color: #363dc8;
        border: 1px solid #363dc8 !important;
      }
    }

    .gc-btn-primary {
      min-width: 80px;
      height: 32px;
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      font-family: var(--font-family-Medium);
      background-image: linear-gradient(-89deg, #acbaf9 0%, #5764f1 100%);
      border-radius: 4px;

      &:hover {
        color: #f6f6f6;
        background-image: linear-gradient(270deg, #363dc8 0%, #1d1f9f 100%);
      }
    }
  }
}
