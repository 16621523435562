.tryFreeBoxContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 0;
  background-image: linear-gradient(to right, #fff, #fff), linear-gradient(90deg, #ffc24c, #ffe9b8);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border: 3px solid transparent;
  border-radius: 24px;
  box-shadow: 0 2px 8px 0 #0000001a;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(../../../assets/images/c-try-free-box-bg.png) no-repeat left top;
    background-size: contain;
    border-radius: 24px;
    content: '';

    @media screen and (max-width:1134px) {
      background-size: cover;
    }
  }

  :global {
    .c-search-type-main {
      position: relative;
      z-index: 100;
      width: 500px;
      margin-top: 54px;

      @media screen and (max-width:1180px) {
        margin-top: 40px;
        width: 70%;
        min-width: 240px;
      }

      .ant-input-lg {
        height: 100%;
        background: unset;
        border: 1px solid #fe8102;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;

        &.ant-input-affix-wrapper-focused {
          border-color: #fe8102 !important;
        }
      }
    }
  }
}

.contentTextBox {
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 100%;

  :global {
    .text-item {
      text-align: center;

      &.is-default {
        color: #1d2129;
        font-weight: 700;
        font-size: 32px;
        font-family: var(--font-family-Bold);

        @media screen and (max-width:1180px) {
          font-size: 24px;
        }

        @media screen and (max-width:960px) {
          font-size: 20px;
        }

        @media screen and (max-width:768px) {
          font-size: 16px;
        }
      }

      &.is-title {
        color: transparent;
        font-weight: 700;
        font-size: 72px;
        font-family: var(--font-family-Bold);
        line-height: 1.4;
        background-image: linear-gradient(to right, #f53f3f, #f77234, #ffb93b);
        -webkit-background-clip: text;

        @media screen and (max-width:1180px) {
          font-size: 40px;
          line-height: 1.2;
        }

        @media screen and (max-width:960px) {
          font-size: 32px;
        }

        @media screen and (max-width:768px) {
          font-size: 24px;
        }

        &.is-small {
          font-size: 64px;

          @media screen and (max-width:1180px) {
            font-size: 40px;
            line-height: 1.2;
          }

          @media screen and (max-width:960px) {
            font-size: 32px;
          }

          @media screen and (max-width:768px) {
            font-size: 24px;
          }
        }
      }

      &.is-extra-detail {
        color: #86909c;
        font-weight: 400;
        font-size: 24px;
        font-family: var(--font-family-Regular);
        line-height: 32px;
        padding: 24px 24px 0;

        @media screen and (max-width:1180px) {
          font-size: 20px;
          line-height: 1.2;
        }

        @media screen and (max-width:960px) {
          font-size: 16px;
        }

        @media screen and (max-width:768px) {
          font-size: 14px;

        }

        &.is-small {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.2px;
        }
      }
    }
  }
}

.decorate {
  position: absolute;
  display: inline-flex;
  z-index: 888;

  &.isNail {
    top: -48px;
    right: -48px;

    :global {
      img {
        width: 104px;
        height: 104px;
      }
    }
  }

  &.isRock {
    right: -168px;
    bottom: 48px;

    @media screen and (max-width:1180px) {
      right: -88px;
      bottom: 16px;
    }

    :global {
      img {
        width: 340px;
        height: 242px;

        @media screen and (max-width:1180px) {
          width: 160px;
          height: unset;
        }
      }
    }
  }
}