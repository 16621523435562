.ant-btn {
  &.c-btn-size-md {
    z-index: 999;
    height: 40px;
    padding: 0 16px;
    font-size: 16px;
    border-radius: 6px;
  }

  &.c-btn-has-arrow {
    padding: 0 40px 0 16px;

    &:hover {
      .anticon-right {
        right: 12px;
        transition: ease-in-out 0.16s;

        &::before {
          width: 16px;
          transition: ease-in-out 0.16s;
        }
      }
    }

    .anticon-right {
      position: absolute;
      top: 0;
      right: 16px;
      bottom: 0;
      display: inline-flex;
      align-items: center;
      margin: auto;
      transition: ease-in-out 0.24s;

      &::before {
        display: inline-flex;
        width: 0;
        height: 1px;
        margin-right: -10px;
        background-color: #fff;
        border-radius: 999px;
        transition: ease-in-out 0.24s;
        content: '';
      }
    }
  }
}
