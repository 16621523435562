.ant-input-search {
  &.c-search-type-main {
    .ant-input-wrapper {
      height: 56px;

      @media screen and (max-width:1180px) {
        height: 40px;
      }

      .ant-input-affix-wrapper {
        height: 100%;
        background: unset;
        border: 1px solid #fe8102;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;

        &.ant-input-affix-wrapper-focused {
          border-color: #fe8102 !important;
        }

        .ant-input {
          background: unset;

          &:focus {
            border-color: #fe8102;
          }

          &::placeholder {
            color: #4d5869;
          }
        }
      }

      .ant-input-group-addon {
        background-color: transparent;

        .ant-btn {
          height: 100%;
          color: #fff;
          font-weight: 700;
          font-size: 18px;
          font-family: var(--font-family-Bold);
          background-image: linear-gradient(-89deg, #f7ba1e 0%, #ff7d00 100%);
          border-radius: 0 8px 8px 0;

          @media screen and (max-width:1180px) {
            font-size: 16px;
          }

          @media screen and (max-width:768px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}